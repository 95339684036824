<!-- front/CookieBanner.client.vue-->
<template>
  <div>
    <div v-if="cookiesAccepted === null" class="cookie-banner">
      <p>{{ $t('cookieMessage') }}</p>
      <button @click="acceptCookies">{{ $t('cookieAccept') }}</button>
      <button @click="declineCookies">{{ $t('cookieDecline') }}</button>
    </div>
    <button
      v-if="cookiesAccepted === 'true' || cookiesAccepted === 'false'"
      @click="revokeCookies"
      class="revoke-button"
    >
      <Icon name="simple-icons:cookiecutter" />
    </button>
  </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig()
const { locale } = useI18n()

const route = useRoute()

const ga4Id = runtimeConfig.public.ga4Id
const gtmId = runtimeConfig.public.gtmId
const gtmAdWords = runtimeConfig.public.gtmAdWords
const fbPixelId = runtimeConfig.public.fbPixelId

const gtmStore = useGtmStore()
const ga4Store = useGa4Store()
const adwordsStore = useAdWordsStore()
const fbPixelStore = useFbPixelStore()
const cookiesAccepted = ref(null)

onMounted(() => {
  if (process.client) {
    cookiesAccepted.value = sessionStorage.getItem('cookiesAccepted')
    if (cookiesAccepted.value === 'true') {
      if (ga4Id !== 'false') {
        ga4Store.init(ga4Id)
      }
      if (gtmId !== 'false') {
        gtmStore.init(gtmId)
      }
      if (gtmAdWords !== 'false') {
        adwordsStore.init(gtmAdWords)
      }
      if (fbPixelId !== 'false') {
        fbPixelStore.init(fbPixelId)
      }
    }
  }

  // Get the lang query parameter and set the locale
  const lang = route.query.lang
  if (lang && lang !== locale.value) {
    locale.value = lang
  }
})

const acceptCookies = () => {
  cookiesAccepted.value = 'true'
  if (process.client) {
    sessionStorage.setItem('cookiesAccepted', 'true')
    if (ga4Id !== 'false') {
      ga4Store.init(ga4Id)
    }
    if (gtmId !== 'false') {
      gtmStore.init(gtmId)
    }
    if (gtmAdWords !== 'false') {
      adwordsStore.init(gtmAdWords)
    }
    if (fbPixelId !== 'false') {
      fbPixelStore.init(fbPixelId)
    }
  }
}

const declineCookies = () => {
  cookiesAccepted.value = 'false'
  if (process.client) {
    sessionStorage.setItem('cookiesAccepted', 'false')
    if (ga4Id !== 'false') {
      ga4Store.stop()
    }
    if (gtmId !== 'false') {
      gtmStore.stop()
    }
    if (gtmAdWords !== 'false') {
      adwordsStore.stop()
    }
    if (fbPixelId !== 'false') {
      fbPixelStore.stop()
    }
  }
}

const revokeCookies = () => {
  cookiesAccepted.value = null
  if (process.client) {
    sessionStorage.removeItem('cookiesAccepted')
    if (ga4Id !== 'false') {
      ga4Store.stop()
    }
    if (gtmId !== 'false') {
      gtmStore.stop()
    }
    if (gtmAdWords !== 'false') {
      adwordsStore.stop()
    }
    if (fbPixelId !== 'false') {
      fbPixelStore.stop()
    }
  }
}
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--secondary-color);
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 999;
}

.cookie-banner p {
  color: white;
}

.cookie-banner button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: opacity 0.3s;
  text-transform: uppercase;
}

.cookie-banner button:hover {
  opacity: 0.8;
}

.cookie-banner button:last-child {
  background-color: var(--red);
}

.cookie-banner button:last-child:hover {
  background-color: var(--red);
}

.revoke-button {
  height: 4em;
  width: 4em;
  position: fixed;
  z-index: 999;
  bottom: 1em;
  right: 2em;
  background-color: var(--secondary-color);
  color: white;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.revoke-button:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--white);
}

.revoke-button svg {
  width: 2em;
  height: 2em;
}
</style>
